:root {
  --home-bg: #F5EBEB;
  --C_primary: #D70900;
  --C_primary_hover: #b35b4c;
  --C_white: white;
  --C_black: #333;
  --C_grey: black;
  --scroll: #aaa;
}

.text-semibold {
  font-weight: 500;
}

.swal2-popup,
.swal2-container {
  z-index: 9999;
}

@font-face {
  font-family: SofiaPro;
  src: url('./assets/fonts/Sofia\ Pro\ Regular\ Az.otf');
}

@font-face {
  font-family: SofiaProBold;
  src: url('./assets/fonts/Sofia\ Pro\ Bold\ Az.otf');
}

@font-face {
  font-family: Lota;
  src: url('./assets/fonts/Los\ Andes\ \ Lota\ Grotesque\ Regular.otf');
}

body {
  font-family: SofiaPro !important;
  position: relative;
}

*::-webkit-scrollbar,
body::-webkit-scrollbar,
.App::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

.tab-component-bs::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

body::-webkit-scrollbar-thumb,
.App::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, #333, var(--scroll));
  border-radius: 10px;
  font-family: sofiaPro !important;
}

body::-webkit-scrollbar-track,
.App::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
  width: 8px;
}

@media print {

  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden
  }
}

@media screen and (min-width: 768px) {
  .overflow-unset {
    overflow: unset;
  }
}

.fadeLeft {
  transition: all .5s;
  left: 0;
}

.date-picker-wrapper .react-datepicker__input-container input::placeholder {
  color: #D70900;
}

.bg-image {
  height: 100vh;
  background-image: url('./assets/images/conference.jpeg');
  background-size: cover;
  background-position: 100% 100vh;
  /* background-repeat: no-repeat; */
  position: relative;
}

.bg-image>div {
  background-color: rgb(0, 0, 0, .6);
  position: absolute;
  /* width: 100%; */
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}